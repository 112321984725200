import { Injectable } from "@angular/core";

interface Script {
  name: string;
  src: string;
  async: boolean;
  text: string;
  htmlTag: string;
  loaded?: boolean; // Add 'loaded' as an optional property
}

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: Script[] = [];

  constructor() {
    // Initialize the scripts array with the required scripts
    this.scripts.push(
      { name: 'gtag1', async: true, src: 'https://www.googletagmanager.com/gtag/js?id=G-B14QZVRV3B', text: "", htmlTag: "head" },
      { name: 'gtag', async: false, src: '', text: "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'G-B14QZVRV3B');", htmlTag: "head" },
      { name: 'gtm', async: false, src: '', text: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'}); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);}) (window, document, 'script', 'dataLayer', 'GTM-PFNGWWM');", htmlTag: "body"}
    );
  }

  private loadScript(script: Script): Promise<any> {
    return new Promise((resolve, reject) => {
      // Check if the script is already loaded
      if (script.src && this.scripts.find(s => s.name === script.name && s.src === script.src)?.loaded) {
        resolve({ script: script.name, loaded: true, status: 'Already Loaded' });
        return;
      }

      // Create a script element
      const dynamicScript = document.createElement('script');
      dynamicScript.type = 'text/javascript';
      dynamicScript.async = script.async;

      if (script.src) {
        dynamicScript.src = script.src;
      }

      if (script.text) {
        dynamicScript.text = script.text;
      }

      // Set the script's onload and onerror callbacks
      dynamicScript.onload = () => {
        script.loaded = true; // Mark the script as loaded
        resolve({ script: script.name, loaded: true, status: 'Loaded' });
      };

      dynamicScript.onerror = () => {
        reject({ script: script.name, loaded: false, status: 'Failed to load' });
      };

      // Append the script to the head or body based on the htmlTag property
      const targetElement = script.htmlTag === 'head' ? document.head : document.body;
      targetElement.appendChild(dynamicScript);
    });
  }

  load(...scripts: string[]): Promise<any[]> {
    const promises: Promise<any>[] = [];

    // Load the specified scripts
    scripts.forEach((name) => {
      const script = this.scripts.find(s => s.name === name);
      if (script) {
        promises.push(this.loadScript(script));
      }
    });

    return Promise.all(promises);
  }

  removeGACookies(): void {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.indexOf('_ga') === 0) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }
  }
}
