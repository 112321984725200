import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';  
import { CollapseModule } from "ngx-bootstrap/collapse";



import { LandinghomepageComponent } from './pages/landinghomepage/landinghomepage.component';
import { CookiesPolicyComponent } from './pages/examples/cookies-policy/cookies-policy.component';
import { RestoComponent } from './pages/examples/resto/resto.component';
import { ConfiguracionBasicaDocsComponent } from './pages/examples/configuracion-basica-docs/configuracion-basica-docs.component';

@NgModule({
    declarations: [
        AppComponent,
        LandinghomepageComponent,
        CookiesPolicyComponent,
        RestoComponent,
        ConfiguracionBasicaDocsComponent
        // IndexComponent,
        // ProfilepageComponent,
        // RegisterpageComponent,
        // LandingpageComponent
    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        AppRoutingModule,
        ReactiveFormsModule,
        CommonModule,
        CollapseModule.forRoot()
// BsDropdownModule.forRoot(),
        // ProgressbarModule.forRoot(),
        // TooltipModule.forRoot(),
        // CollapseModule.forRoot(),
        // TabsModule.forRoot(),
        // PaginationModule.forRoot(),
        // AlertModule.forRoot(),
        // BsDatepickerModule.forRoot(),
        // CarouselModule.forRoot(),
        // ModalModule.forRoot()
        //LandinghomepageComponent,
    ],
    providers: [    { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent],

})
export class AppModule { }
