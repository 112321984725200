import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service'
import { ScriptService } from 'src/app/script.service';
import { ActivatedRoute, Router } from '@angular/router';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

declare var gtag

declare var dailymotion: any;




@Component({
  selector: 'app-landinghomepage',
  templateUrl: './landinghomepage.component.html',
  styleUrls: ['./landinghomepage.component.scss']
})
export class LandinghomepageComponent implements OnInit, OnDestroy {
  newContact: FormGroup;
  isCollapsed = false;
  focus;
  focus1;
  focus2;
  date = new Date();
  pagination = 3;
  pagination1 = 1;
  messageError = false;
  emailRegx = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  loading: boolean;
  recibido: boolean;
  data: any;
  isChecked = true;
  popupElement: any
  lastFragment: string;
  images: string[] = ['assets/img/a.jpg', 'assets/img/b.jpeg', 'assets/img/d.jpeg', 'assets/img/d.jpg'];
  currentImageIndex = 0;
  loader: any;

  @ViewChild('playerContainer', { static: false }) playerContainer!: ElementRef;
  playerInitialized = false;

  @ViewChild('nosotros') nosotrosSection: ElementRef;
  show: boolean;



  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder,
    private scriptService: ScriptService,
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.loader = true;


    this.route.params.subscribe(params => {
      const alias = params['alias'];
      const url = this.router.url;

      if (alias && url) {
        if (url.includes('/menu')) {
          this.fetchAndRedirect(alias, 'menu');
        } else if (url.includes('/reservas')) {
          this.fetchAndRedirect(alias, 'reservas');
        }

      }
    });





  }

  

  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  


  fetchAndRedirect(alias: string, endpoint: string): void {
    this.apiService.getAlias(alias, endpoint).subscribe((success: any) => {
      console.log(success);
      const url = success.url;
      window.location.href = url;
    }, error => {
      console.log(error);
    });
  }

  selectPlan(plan: string) {
    const newTab = window.open("https://fragtium.com/resto/registro?selectedPlan=" + plan, '_blank');
    newTab.focus();
    gtag('event', 'PLAN_SELECTION', {
      'event_category': 'ADQUISITION',
      'event_label': 'SELECTED_PLAN',
      'value': plan
    })

  }

  initializePlayer(): void {
        dailymotion
    .createPlayer("playerContainer", {
      video: "x84sh87",
  })
  }



  ngOnInit() {


    setTimeout(() => {
      const spinner = document.getElementById('loading-spinner');
      if (spinner) {
        spinner.style.display = 'none';
      }
      this.loader = false;
      this.popupElement = document.getElementById('your-popup-element-id');
      this.checkGDPRcookie()

      

    }
      , 2000);
     



    var body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");

    this.newContact = this.formBuilder.group({
      'name': [null, Validators.required],
      'email': [null, [Validators.required, Validators.pattern(this.emailRegx)]],
      'message': [null, Validators.required]
    });

    //scroll to top
    window.scrollTo(0, 0);

    this.startAutoplay();





  }

  showNextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
  }

  showPreviousImage() {
    this.currentImageIndex = (this.currentImageIndex - 1 + this.images.length) % this.images.length;
  }

  private startAutoplay() {
    setInterval(() => {
      this.showNextImage();
    }, 500); // Set the interval to 3 seconds (adjust as needed)
  }

  ngAfterViewChecked() {
    if (!this.playerInitialized && this.playerContainer) {
      this.initializePlayer();
      this.playerInitialized = true;
    }
  }

  ngAfterViewInit(): void {

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        setTimeout(() => {
          const element = document.getElementById(fragment);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 300);
      }
    });


  }

  //function to scroll to the element by id
  scrollToElement(elementId: string) {
    document.getElementById(elementId).scrollIntoView({ behavior: "auto" });
  }




  sendEventAndScroll(event: string, category: string, label: string, value: string) {
    if (value == "iniciar sesion") {
      window.open("https://fragtium.com/resto/login", '_blank').focus();
    }
    if (value == "resto") {
      this.router.navigate(['/resto']);
    } else {
      this.scrollToElement(value);
    }

    gtag('event', event, {
      'event_category': category,
      'event_label': label,
      'value': value
    })
  }


  getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    }
    else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  checkGDPRcookie() {
    var fragtiumCookie = this.getCookie("fragtium-consent");
    if (fragtiumCookie == null) {
      this.popupElement.classList.add('show-popup');
    } else {
      //if cookie value is "accepted consent" then load the scripts
      if (fragtiumCookie.split(';')[0] == "accepted consent") {
        this.scriptService.load('gtag1', 'gtag', 'gtm').then((result) => {
          console.log(result);
          // Do something after scripts are loaded (if needed)
        }).catch((error) => {
          console.error(error);
        });
      }
    }
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '/', domain: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    let cdomain: string = domain ? `; domain=${domain}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}${cdomain}`;
  }

  submitConsent() {
    const domain = window.location.hostname.includes('localhost') ? '' : '.fragtium.com';
    this.setCookie("fragtium-consent", "accepted consent", 365, "/", domain)
    if (this.isChecked) {
      console.log("consent checked")
    }

    this.scriptService.load('gtag1', 'gtag', 'gtm').then((result) => {
      console.log(result);
      // Do something after scripts are loaded (if needed)
    }).catch((error) => {
      console.error(error);
    });

    this.popupElement = document.getElementById('your-popup-element-id');
    this.popupElement.classList.remove("show-popup");
  }

  onNoClick() {
    const domain = window.location.hostname.includes('localhost') ? '' : '.fragtium.com';
    this.setCookie("fragtium-consent", "rejected consent", 30, "/", domain)
    this.popupElement = document.getElementById('your-popup-element-id');
    this.popupElement.classList.remove("show-popup");
    this.scriptService.removeGACookies();
  }

  eliminarCookie() {
    this.deleteCookiesStartingWith('_ga');
  }


  getAllCookies(): { [key: string]: string } {
    const cookies = document.cookie.split(";").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name.trim()] = decodeURIComponent(value);
      return acc;
    }, {});

    return cookies;
  }

  deleteCookiesStartingWith(prefix: string) {
    const cookies = this.getAllCookies();

    for (const cookieName of Object.keys(cookies)) {
      if (cookieName.startsWith(prefix)) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    }
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("index-page");
  }

  showCookies() {
    this.popupElement = document.getElementById('your-popup-element-id');
    this.popupElement.classList.add("show-popup");
  }

  submit() {
    this.loading = true;
    if (!this.newContact.valid) {
      return;
    }
    this.apiService.setContact(JSON.stringify(this.newContact.value)).subscribe(success => {
      this.data = success
      if (this.data.error == false) {
        this.recibido = true

      }
      this.loading = false;


    }, error => {
      if (error) {
        this.recibido = false
      }
      this.loading = false;

    });
  }

  sendTemplate() {
    this.apiService.sendTemplate().subscribe(success => {
      this.data = success
      console.log(this.data.error)
      if (this.data.error == false) {
        this.recibido = true

      }
      this.loading = false;


    }, error => {
      if (error) {
        this.recibido = false
      }
      this.loading = false;

    });

  }



}
