import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  private SERVER_URL = "https://fragtium.com:3000/";

  private REQ_HEADER = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + 'soyuntoken' //JSON.parse(localStorage.getItem('mpManagerToken')
  });


  private OWNER_ID = 1; //TODO make method to get Authorization + owner ID from localstorage


  constructor(private httpClient: HttpClient) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public get() {
    return this.httpClient.get(this.SERVER_URL).pipe(catchError(this.handleError));
  }

    public setContact(idowner: any) {
    return this.httpClient.post(`https://fragtium.com:3000/contact`, idowner, { headers: this.REQ_HEADER });
    }

    public sendTemplate() {
        return this.httpClient.post(`https://fragtium.com:3000/send_booking_confirmation`, {"email": "mariano.a.costanzo@gmail.com"}, { headers: this.REQ_HEADER });
    }


    private REQ_HEADER2 = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
      
    });

    public getAlias(alias: string,url: string) {
        var url = "https://fragtium.com:4800/"+url;
        return this.httpClient.post(`${url}/${alias}`, { headers: this.REQ_HEADER2 });
    }


}
