import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScriptService } from 'src/app/script.service';

declare var gtag

@Component({
  selector: 'app-resto',
  templateUrl: './resto.component.html',
  styleUrls: ['./resto.component.scss']
})
export class RestoComponent implements OnInit {
  images: string[] = ['assets/img/a.jpg', 'assets/img/b.jpeg', 'assets/img/d.jpeg','assets/img/d.jpg'];
  currentImageIndex = 0;
  lastFragment: string;
  popupElement: any
  isChecked = true;



  constructor(private router: Router,
    private scriptService: ScriptService,

    ) { }

  ngOnInit(): void {

    this.popupElement = document.getElementById('your-popup-element-id');
    this.checkGDPRcookie()
    //scroll to top
    window.scrollTo(0, 0);
    this.startAutoplay();

  }

  checkGDPRcookie() {
    var fragtiumCookie = this.getCookie("fragtium-consent");
    if (fragtiumCookie == null) {
      console.log("cookie not found")
        this.popupElement.classList.add('show-popup');
    } else {
      console.log("cookie found")

      console.log(fragtiumCookie.split(';')[0])
      //if cookie value is "accepted consent" then load the scripts
      if(fragtiumCookie.split(';')[0] == "accepted consent"){
        console.log("cookie accepted")
        this.scriptService.load('gtag1', 'gtag', 'gtm').then((result) => {
          console.log(result);
          // Do something after scripts are loaded (if needed)
        }).catch((error) => {
          console.error(error);
        });
      }
    }
  }

  getCookie(name) { 
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    }
    else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
  }


  showNextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
  }
  
  showPreviousImage() {
    this.currentImageIndex = (this.currentImageIndex - 1 + this.images.length) % this.images.length;
  }

  private startAutoplay() {
    setInterval(() => {
      this.showNextImage();
    }, 500); // Set the interval to 3 seconds (adjust as needed)
  }
  
  navigateToId(value: string) {
    this.router.navigate(['/home'], { fragment: value });
  }
  //function to scroll to the element by id
  scrollToElement(elementId: string){
    document.getElementById(elementId).scrollIntoView({behavior: "auto"});
  }


  //scroll to the top
  scrollToTop(){
    window.scrollTo(0, 0);
  }

  sendEventAndScroll(event: string, category: string, label: string, value: string) {
    
    
    if(value == "iniciar sesion"){
      window.open("https://fragtium.com/resto/login", '_blank').focus();      
    }else 
    {
      this.navigateToId(value);
    }

    gtag('event', event, {
      'event_category': category,
      'event_label': label,
      'value': value   })
    }

    selectPlan(plan: string){
      const newTab = window.open("https://fragtium.com/resto/registro?selectedPlan="+plan, '_blank');
      newTab.focus();
        gtag('event', 'PLAN_SELECTION', {
          'event_category': 'ADQUISITION',
          'event_label': 'SELECTED_PLAN',
          'value': plan   })

    }

    showCookies() {
      this.popupElement = document.getElementById('your-popup-element-id');
      this.popupElement.classList.add("show-popup");
      }
      
    ngOnDestroy() {
      var body = document.getElementsByTagName("body")[0];
      body.classList.remove("register-page");
    }
  
    private setCookie(name: string, value: string, expireDays: number, path: string = '/', domain: string = '') {
      let d: Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      let expires: string = `expires=${d.toUTCString()}`;
      let cpath: string = path ? `; path=${path}` : '';
      let cdomain: string = domain ? `; domain=${domain}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}${cdomain}`;
    }
  
    submitConsent() {
      const domain = window.location.hostname.includes('localhost') ? '' : '.fragtium.com';
      this.setCookie("fragtium-consent", "accepted consent", 365, "/", domain )
      if(this.isChecked){
        console.log("consent checked")
      }
  
      this.scriptService.load('gtag1', 'gtag', 'gtm').then((result) => {
        console.log(result);
        // Do something after scripts are loaded (if needed)
      }).catch((error) => {
        console.error(error);
      });
  
      this.popupElement = document.getElementById('your-popup-element-id');
      this.popupElement.classList.remove("show-popup");
    }
  
    onNoClick() {
      const domain = window.location.hostname.includes('localhost') ? '' : '.fragtium.com';
      this.setCookie("fragtium-consent", "rejected consent", 30, "/", domain)
      this.popupElement = document.getElementById('your-popup-element-id');
      this.popupElement.classList.remove("show-popup");
      this.scriptService.removeGACookies();
    }
  
    eliminarCookie() {
      this.deleteCookiesStartingWith('_ga');
    }
  
  
    getAllCookies(): { [key: string]: string } {
      const cookies = document.cookie.split(";").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name.trim()] = decodeURIComponent(value);
        return acc;
      }, {});
    
      return cookies;
    }
  
    deleteCookiesStartingWith(prefix: string) {
      const cookies = this.getAllCookies();
  
      for (const cookieName of Object.keys(cookies)) {
        if (cookieName.startsWith(prefix)) {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      }
    }
    
}
