import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { ScriptService } from 'src/app/script.service';


@Component({
  selector: "app-termspage",
  templateUrl: "terms.component.html",
  styleUrls: ['terms.component.scss']

})
export class TermspageComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  focus;
  focus1;
  focus2;
  popupElement: HTMLElement;
  isChecked = true;

  constructor(
    private scriptService: ScriptService,

  ) {
    
  }
  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e) {
    var squares1 = document.getElementById("square1");
    var squares2 = document.getElementById("square2");
    var squares3 = document.getElementById("square3");
    var squares4 = document.getElementById("square4");
    var squares5 = document.getElementById("square5");
    var squares6 = document.getElementById("square6");
    var squares7 = document.getElementById("square7");
    var squares8 = document.getElementById("square8");

    var posX = e.clientX - window.innerWidth / 2;
    var posY = e.clientY - window.innerWidth / 6;

    squares1.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares2.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares3.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares4.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares5.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares6.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)";
    squares7.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.02 +
      "deg) rotateX(" +
      posY * -0.02 +
      "deg)";
    squares8.style.transform =
      "perspective(500px) rotateY(" +
      posX * 0.02 +
      "deg) rotateX(" +
      posY * -0.02 +
      "deg)";
  }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");

    this.popupElement = document.getElementById('your-popup-element-id');
    this.checkGDPRcookie()
    this.onMouseMove(event);
  }

  checkGDPRcookie() {
    var fragtiumCookie = this.getCookie("fragtium-consent");
    if (fragtiumCookie == null) {
        this.popupElement.classList.add('show-popup');
    } else {
      //if cookie value is "accepted consent" then load the scripts
      if(fragtiumCookie.split(';')[0] == "accepted consent"){
        this.scriptService.load('gtag1', 'gtag', 'gtm').then((result) => {
          console.log(result);
          // Do something after scripts are loaded (if needed)
        }).catch((error) => {
          console.error(error);
        });
      }
    }
  }

  getCookie(name) { 
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    }
    else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
  }


  showCookies() {
    this.popupElement = document.getElementById('your-popup-element-id');
    this.popupElement.classList.add("show-popup");
    }
    
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("register-page");
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '/', domain: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    let cdomain: string = domain ? `; domain=${domain}` : '';
  document.cookie = `${name}=${value}; ${expires}${cpath}${cdomain}`;
  }

  submitConsent() {
    const domain = window.location.hostname.includes('localhost') ? '' : '.fragtium.com';
    this.setCookie("fragtium-consent", "accepted consent", 365, "/", domain )
    if(this.isChecked){
      console.log("consent checked")
    }

    this.scriptService.load('gtag1', 'gtag', 'gtm').then((result) => {
      console.log(result);
      // Do something after scripts are loaded (if needed)
    }).catch((error) => {
      console.error(error);
    });

    this.popupElement = document.getElementById('your-popup-element-id');
    this.popupElement.classList.remove("show-popup");
  }

  onNoClick() {
    const domain = window.location.hostname.includes('localhost') ? '' : '.fragtium.com';
    this.setCookie("fragtium-consent", "rejected consent", 30, "/", domain)
    this.popupElement = document.getElementById('your-popup-element-id');
    this.popupElement.classList.remove("show-popup");
    this.scriptService.removeGACookies();
  }

  eliminarCookie() {
    this.deleteCookiesStartingWith('_ga');
  }


  getAllCookies(): { [key: string]: string } {
    const cookies = document.cookie.split(";").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name.trim()] = decodeURIComponent(value);
      return acc;
    }, {});
  
    return cookies;
  }

  deleteCookiesStartingWith(prefix: string) {
    const cookies = this.getAllCookies();

    for (const cookieName of Object.keys(cookies)) {
      if (cookieName.startsWith(prefix)) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    }
  }
}
