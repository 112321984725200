import { NgModule } from "@angular/core";
import { CommonModule, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { ProfilepageComponent } from "./pages/examples/profilepage/profilepage.component";
import { TermspageComponent } from "./pages/examples/terms/terms.component";
import { LandinghomepageComponent } from "./pages/landinghomepage/landinghomepage.component"
import { PrivacypageComponent } from "./pages/examples/privacy/privacy.component";
import { CookiesPolicyComponent } from './pages/examples/cookies-policy/cookies-policy.component';
import { RestoComponent } from "./pages/examples/resto/resto.component";
import { CarouselComponent } from "ngx-bootstrap/carousel";
import { ConfiguracionBasicaDocsComponent } from "./pages/examples/configuracion-basica-docs/configuracion-basica-docs.component";



const routes: Routes = [
  {path: "reservas/:alias", component: LandinghomepageComponent},
  {path: "menu/:alias", component: LandinghomepageComponent},
  { path: "profile", component: ProfilepageComponent },
  { path: "condiciones-de-uso", component: TermspageComponent },
  { path: "privacidad", component: PrivacypageComponent },
  { path: 'cookies', component: CookiesPolicyComponent },
  { path: 'resto', component: RestoComponent },
  { path: 'carouselExampleIndicators', component: CarouselComponent },
  { path: "", component: LandinghomepageComponent },
  { path: "home", component: LandinghomepageComponent },
  {path: "configuracion-basica-docs", component: ConfiguracionBasicaDocsComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full' }



];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
    exports: [],
  declarations: []
})
export class AppRoutingModule {}
